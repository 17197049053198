<template>
  <div class="-self p-2 text-center" v-if="isShown">
    <p>
      {{ $trans.translate("This website uses cookie files.") }}
    </p>
    <div>
      <button class="button mx-1" @click="hide(true)">
        <icon-span type="check" fixed-width />
        {{ $trans.translate("Agree") }}
      </button>
      <button class="button mx-1" @click="hide(false)">
        <icon-span type="times" fixed-width />
        {{ $trans.translate("Disagree") }}
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isShown: true,
      }
    },
    methods: {
      hide(agree) {
        if (agree) {
          this.$cookies.set('hidecookiealert', 'true')
        }
        this.isShown = false
      },
    },
    mounted() {
      this.isShown = !this.$cookies.get('hidecookiealert')
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vars';

  .-self {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: color("primary-dark");
    opacity: 0.875;
  }
</style>
