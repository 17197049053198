<template>
  <base-view class="-self">
    <template #header>
      <div class="container media-flex">
        <div class="left">
          <title-div>
            {{ $trans.translate("Alexander") }}
            <br>
            {{ $trans.translate("Khlebushchev") }}
          </title-div>
          <div class="text-muted">{{ $trans.translate("Personal website") }}</div>
          <div>
            <div class="py-4 media-none">
              <router-link to="/projects" class="button is-primary px-3 py-2">
                {{ $trans.translate("Projects") }}
              </router-link>
              &nbsp;
              <router-link to="/blog" class="button is-primary-hover px-3 py-2">
                {{ $trans.translate("Blog") }}
              </router-link>
            </div>
          </div>
          <div class="text-semi-muted media-none" v-if="quote">
            <div class="text-text py-1">
              {{ quote.text }}
            </div>
            <div class="text-text text-small text-right">
              {{ quote.author }}
            </div>
          </div>
        </div>
        <div>
          <img src="@/assets/images/alexander-khlebushchev.png" class="image">
        </div>
      </div>

      <div class="py-3 media-block">
        <div class="div-flex">
          <div>
            <router-link to="/projects" class="button is-primary px-3 py-2">
              {{ $trans.translate("Projects") }}
            </router-link>
          </div>
          <div>
            <router-link to="/blog" class="button is-primary-hover px-3 py-2">
              {{ $trans.translate("Blog") }}
            </router-link>
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <div class="text-semi-muted media-block" v-if="quote">
        <div class="text-text py-1">
          {{ quote.text }}
        </div>
        <div class="text-text text-small text-right">
          {{ quote.author }}
        </div>
      </div>

      <contacts-snake class="my-3 media-none" />
      <contacts-snake class="my-3 media-block" is-vertical />
    </template>
  </base-view>
</template>

<script>
  import ContactsSnake from '@/components/ContactsSnake'

  export default {
    components: {
      ContactsSnake,
    },
    data() {
      return {
        quote: null,
      }
    },
    async mounted() {
      try {
        const resp = await this.$api.quotes.random({lang: this.$trans.lang})
        this.quote = resp.body
      } catch (resp) {/**/}
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vars';

  .-self {
    .image {
      max-width: calc(0.5 * $container-max-width); 
      width: 100%;
    }

    .left {
      padding-top: 0;

      @media screen and (min-width: $media-min-width) {
        padding-top: 60px;
      }
    }

    .media-block .div-flex {
      justify-content: space-around;
    }
  }
</style>
