var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"refComponent",staticClass:"-self"},[_vm._l((_vm.stars),function(star,index){return _c('div',{key:index,staticClass:"star",style:({
         left: star.position[0], 
         top: star.position[1], 
         backgroundColor: star.color,
         width: _vm.starSize + 'px',
         height: _vm.starSize + 'px',
         borderRadius: Math.floor(0.5 * _vm.starSize) + 'px',
       })})}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }