export default class Api {
  constructor(resource) {
    this.contacts = resource('contacts{/id}')
    this.quotes = resource('quotes{/id}', {}, {
      random: {method: 'GET', url: 'quotes/random'},
    })
    this.files = resource('files{/id}')
    this.blogTags = resource('blog/tags{/id}')
    this.blogArticles = resource('blog/articles{/id}', {}, {
      getByShortURL: {
        method: 'GET', 
        url: 'blog/articles/get-by-short-url{/shorturl}',
      },
    })
    this.projects = resource('projects{/id}')
    this.cv = resource('cv{/id}')
    this.visits = resource('visits{/id}')
    this.trans = resource('trans{/id}')
  }
}
