<template>
  <base-view class="-self">
    <template #header>
      <navigation-div />
      <title-div>{{ $trans.translate("Blog") }}</title-div>
    </template>
    <template #default>
      <div class="media-none tags py-3" v-if="tagMap">
        <div class="div-flex">
          <div v-for="tag in Object.values(tagMap)" :key="tag.id">
            <a href="#" class="button" 
               :class="{'is-active': selectedTags.includes(tag.id)}"
               @click="tagClicked(tag.id)">
              {{ tag.name }}
            </a>
          </div>
        </div>
      </div>

      <div v-for="item in getItemsFiltered()" :key="item.id" class="media-flex mb-3 items">
        <div class="image">
          <img :src="item.image ? item.image.url : ''">
        </div>
        <div class="px-2 media-none"></div>
        <div class="px-1">
          <div class="media-flex text-muted">
            <div class="pt-2 pr-2">{{ item.date }}</div>
            <div v-for="tag in getArticleTagNames(item)" :key="tag.id" 
                 class="pt-2 pr-2">
              #{{ tag.name }}
            </div>
          </div>
          <div class="py-2">
            <router-link :to="getArticleURL(item)">
              <strong>{{ item.title }}</strong>
            </router-link>
          </div>
          <div class="text-text text-semi-muted">
            {{ item.text }} ...
          </div>
        </div>
        <div class="px-3 media-none"></div>
        <div class="media-block">
          <router-link :to="getArticleURL(item)" 
                       class="mt-2 button is-primary">
            Read this article
          </router-link>
        </div>
      </div>

      <div class="sep-div"></div>

      <up-arrow-div />
    </template>
  </base-view>
</template>

<script>
  import { buildMap, haveIntersection } from '@/utils'

  export default {
    data() {
      return {
        tagMap: null,
        items: null,
        selectedTags: [],
      }
    },
    methods: {
      getArticleTagNames(item) {
        return item.tags.map(tagId => this.tagMap[tagId])
      },

      getArticleURL(item) {
        return `/blog/article/${item.short_url}`
      },

      tagClicked(tagId) {
        const idx = this.selectedTags.indexOf(tagId)
        if (idx == -1) {
          this.selectedTags.push(tagId)
        } else {
          this.selectedTags.splice(idx, 1)
        }
      },

      getItemsFiltered() {
        if (this.selectedTags.length) {
          return this.items.filter(
            item => haveIntersection(item.tags, this.selectedTags)
          )
        } else {
          return this.items
        }
      },
    },
    async mounted() {
      const [respItems, respTags] = await Promise.all([
        this.$api.blogArticles.get({lang: this.$trans.lang, preview: 200}),
        this.$api.blogTags.get({lang: this.$trans.lang})
      ])
      this.items = respItems.body
      this.tagMap = buildMap(respTags.body, 'id')
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vars';

  .-self {
    .media-flex {
      display: block;

      @media screen and (min-width: $media-min-width) {
        display: flex;
        justify-content: flex-start;
      }
    }

    .tags .div-flex {
      justify-content: space-around;

      a.is-active {
        color: color('secondary');
        border-color: color('secondary');
      }
    }

    .items {
      background-color: color('bg-dark');

      .image {
        img {
          width: 100%;

          @media screen and (min-width: $media-min-width) {
            display: block;
            width: 200px;
            height: 200px;
            object-fit: cover;
          }
        }
      }

      a {
        color: color('light');

        &:hover {
          color: color('secondary');
        }
      }

      .button {
        width: calc(100% - 4px);
        text-align: center;
        padding: 10px 0;
      }
    }
  }
</style>
