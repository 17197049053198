<template>
  <base-view class="-self">
    <template #header>
      <navigation-div />
      <title-div>{{ $trans.translate("CV") }}</title-div>
    </template>
    <template #default>
      <div v-if="items">
        <object v-for="item in items" :key="item.id"
                :data="getCVURL(item)" 
                type="application/pdf">
          It looks like your browser doesn't support PDFs.
          Please, download it by 
          <a :href="getCVURL(item)" download="cv.pdf">the link</a>.
        </object>

        <div v-if="!items.length">
          I am so sorry, you are forbidden to view my CV. 
          Please, <router-link to="/contacts">contact</router-link> me 
          so I will give you a correct link.
        </div>
      </div>
    </template>
  </base-view>
</template>

<script>
  export default {
    data() {
      return {
        items: null,
      }
    },
    methods: {
      getSecret() {
        return this.$route.query.secret
      },

      getCVURL(item) {
        return `${item.url}?secret=${this.getSecret()}`
      },
    },
    async mounted() {
      const resp = await this.$api.cv.get({secret: this.getSecret()})
      this.items = resp.body
    },
  }
</script>

<style lang="scss" scoped>
  object {
    display: table;
    margin: 0 auto;

    width: 100%;
    max-width: 900px;
    height: 1200px;
  }
</style>
