if (!String.prototype.format) {
  String.prototype.format = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match, number) { 
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
      ;
    });
  };
}


function buildMap(lst, key) {
  const map = {}
  for (const elem of lst) {
    map[elem[key]] = elem
  }
  return map
}


function haveIntersection(arr1, arr2) {
  return !!arr1.filter(e => arr2.includes(e)).length
}


function inlineUpmathToMathjax(s) {
  s = s.replace(/\$\$/g, '$')
  s = s.replace(/<p>\$(.*?)\$<\/p>/g, '<p class="text-center">$$ $1 $$</p>')
  return s
}


function removeUpmathSpecs(s) {
  s = s.replace(/\$\$/g, '')
  s = s.replace(/##+/g, '')
  return s
}


function zfill(n, z) {
  let s = n.toString()
  while (s.length < z) {
    s = '0' + s
  }
  return s
}


function prettyBytes(size) {
  let value = size
  let suff = 'b'

  if (value > 1024) {
    value /= 1024
    suff = 'kb'
  }
  if (value > 1024) {
    value /= 1024
    suff = 'mb'
  }
  if (value > 1024) {
    value /= 1024
    suff = 'gb'
  }

  if (value >= 100) {
    value = value.toFixed(0)
  } else if (value >= 10) {
    value = value.toFixed(1)
  } else {
    value = value.toFixed(2)
  }

  if (value.indexOf('.') != -1) {
    while (value[value.length - 1] == '0') {
      value = value.substring(0, value.length - 1)
    }

    if (value[value.length - 1] == '.') {
      value = value.substring(0, value.length - 1)
    }
  }

  return value + ' ' + suff
}


export {
  buildMap,
  haveIntersection,
  inlineUpmathToMathjax,
  removeUpmathSpecs,
  zfill,
  prettyBytes,
}
