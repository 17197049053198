<template>
  <div>
    <header-div @show-menu="$refs.refMenu.show()">
      <slot name="header"></slot>
    </header-div>

    <div class="container px-2 pt-2 pb-5">
      <slot></slot>
    </div>

    <footer-div />

    <menu-div ref="refMenu" />

    <cookie-alert />
  </div>
</template>

<script>
  import HeaderDiv from '@/components/HeaderDiv'
  import FooterDiv from '@/components/FooterDiv'
  import MenuDiv from '@/components/MenuDiv'
  import CookieAlert from '@/components/CookieAlert'

  export default {
    components: {
      HeaderDiv,
      FooterDiv,
      MenuDiv,
      CookieAlert,
    },
    async mounted() {
      // Set default meta
      this.$root.$emit('set-meta', {})

      // Send visit statistics
      await this.$api.visits.save({
        uid: this.$cookies.get('uid'),
        url: this.$route.fullPath,
      })
    },
  }
</script>
