<template>
  <base-view class="-self">
    <template #header>
      <navigation-div />
      <title-div>{{ $trans.translate("Files") }}</title-div>
    </template>
    <template #default>
      <table class="table" v-if="items">
        <tr v-for="item in items" :key="item.id">
          <td class="py-3">
            <div>{{ item.name }}</div>
            <div class="text-muted media-block pt-1">
              {{ prettyBytes(item.size) }}
            </div>
          </td>
          <td class="py-3 text-muted media-none">
            {{ prettyBytes(item.size) }}
          </td>
          <td>
            <a :href="item.url" :download="item.name" 
               class="button is-primary">
              <span class="media-none-inline">{{ $trans.translate("Download") }}&nbsp;</span>
              <icon-span type="download" />
            </a>
          </td>
        </tr>
      </table>

      <up-arrow-div />
    </template>
  </base-view>
</template>

<script>
  import { prettyBytes } from '@/utils'

  export default {
    data() {
      return {
        items: null,
      }
    },
    methods: {
      prettyBytes,
    },
    async mounted() {
      const resp = await this.$api.files.get()
      this.items = resp.body
    },
  }
</script>
