<template>
  <base-view>
    <template #header>
      <navigation-div />
      <title-div>{{ $trans.translate("Error 404") }}</title-div>
    </template>
    <template #default>
      <p class="py-2" v-html="$trans.translate('Page {0} not found.')
        .format(`<a href=&quot;${getURL()}&quot;>${getURL()}</a>`)"></p>
    </template>
  </base-view>
</template>

<script>
  export default {
    methods: {
      getURL() {
        return this.$route.fullPath
      },
    },
  }
</script>
