import md5 from 'js-md5'


export default class Trans {
  constructor() {
    this.lang = null
    this.dictionaries = {}
    this.emptyHandler = null
  }

  addDictionary(dict, lang) {
    this.dictionaries[lang] = dict
  }

  translate(phrase) {
    // Get MD5 key
    const key = md5(phrase)

    // Try to translate
    const translation = this.dictionaries[this.lang][key]

    // If no translation and empty handler is set send 
    // the notification to translate
    if (this.lang != 'en') {
      if (!translation && this.emptyHandler) {
        this.emptyHandler({
          lang: this.lang,
          origin: phrase,
        }).catch(() => {})
      }
    }

    // Return the translation
    return translation ? translation : phrase
  }
}
