<template>
  <div class="div-flex">
    <span class="button p-1 mr-2" v-for="item in langs" :key="item" 
          @click="langClick(item)">{{ item }}</span>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        langs: ['en', 'ru', 'eo'],
      }
    },
    methods: {
      langClick(lang) {
        this.$cookies.set('lang', lang)
        window.location.reload()
      },
    },
  }
</script>
