<template>
  <stars-div class="-self py-2 text-muted text-center" 
             :density="0.0002" 
             :saturation="5" 
             :star-size="2">
    <router-link to="/">
      &copy; {{ $trans.translate("Alexander Khlebushchev") }} 
      <span class="text-nowrap">2015 - {{ getYear() }}</span>
    </router-link>
  </stars-div>
</template>

<script>
  import StarsDiv from './StarsDiv.vue'

  export default {
    components: {
      StarsDiv,
    },
    methods: {
      getYear() {
        return new Date().getFullYear()
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vars';

  .-self {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;

    font-size: 18px;

    a {
      color: inherit;
    }
  }
</style>
