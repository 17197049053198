<template>
  <div ref="refComponent" class="-self">
    <div v-for="(star, index) in stars" 
         :key="index" 
         class="star"  
         :style="{
           left: star.position[0], 
           top: star.position[1], 
           backgroundColor: star.color,
           width: starSize + 'px',
           height: starSize + 'px',
           borderRadius: Math.floor(0.5 * starSize) + 'px',
         }"       
    ></div>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      density: {
        type: Number,
        default: 0.0001,
      },
      saturation: {
        type: Number,
        default: 10,
      },
      starSize: {
        type: Number,
        default: 1,
      },
    },
    data() {
      return {
        stars: [],
      }
    },
    methods: {
      draw() {
        // Remove old stars
        this.stars.length = 0

        // Parent div
        const div = this.$refs['refComponent']

        if (div) {
          // Number of stars
          const count = Math.floor(this.density * div.clientHeight * div.clientWidth)

          for (let i = 0; i < count; i++) {
            // Coordinates
            const x = Math.floor(Math.random() * div.clientWidth)
            const y = Math.floor(Math.random() * div.clientHeight)

            // HSL color
            const h = Math.floor(Math.random() * 360)
            const s = this.saturation
            const l = Math.floor(Math.random() * 100)

            const star = {
              color: `hsl(${h}, ${s}%, ${l}%)`,
              position: [x + 'px', y + 'px'],
            }
            this.stars.push(star)
          }
        }
      },
    },
    mounted() {
      // Draw
      this.$nextTick(() => {
        this.draw()
      })

      // Redraw on load
      window.addEventListener("load", () => {
        this.draw()
      })

      // Redraw on resize
      window.addEventListener("resize", () => {
        this.draw()
      })
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vars';

  .-self {
    background-color: color('bg-secondary');
    position: relative;
    z-index: 0;

    .star {
      position: relative;
      display: block;
      position: absolute;
      z-index: -1000;
    }
  }
</style>
