<template>
  <base-view v-if="item">
    <template #header>
      <navigation-div />
      <title-div border-left>{{ item.title }}</title-div>
    </template>
    <template #default>
      <div class="text-text article" v-html="getText()"></div>
      <div class="sep-div"></div>
      <up-arrow-div />
    </template>
  </base-view>
</template>

<script>
  import { inlineUpmathToMathjax } from '@/utils'

  export default {
    data() {
      return {
        item: null,
      }
    },
    methods: {
      getText() {
        return inlineUpmathToMathjax(
          this.$md.render(this.item.text)
        )
      },

      updateMathjax() {
        this.$nextTick(
          () => {
            window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
          }
        )
      },
    },
    async mounted() {
      const resp = await this.$api.blogArticles.getByShortURL({
        shorturl: this.$route.params.shorturl, 
        lang: this.$trans.lang,
      })
      this.item = resp.body
      this.updateMathjax()

      this.$nextTick(() => {
        this.$root.$emit('set-meta', {
          title: this.item.title,
          description: this.item.text.substring(0, 200) + '...',
          image: this.item.image.url,
        })
      })
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vars';

  .article {
    word-wrap: break-word;
    width: 100%;

    ::v-deep {
      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
      }

      table {
        margin: 0 auto;
        max-width: 100%;

        border: 2px solid color('primary-dark');
        border-collapse: collapse;

        td, th {
          padding: 10px 20px;
          background-color: color('bg-secondary');
          border: 2px solid color('primary-dark');
          word-wrap: break-word;
          word-break: break-all;
          table-layout: fixed;
        }

        th {
          background-color: color('primary-dark');
        }
      }

      pre {
        border-left: 5px solid color('primary-dark');
        padding: 1rem;
        line-height: 1.5;
      }

      code {
        padding: 0.25rem;
      }

      pre, code {
        background-color: color('bg-secondary');
      }

      h2, h3 {
        color: color('secondary');
      }

      strong {
        color: color('secondary');
        filter: brightness(16.0);
      }

      .mjx-math {
        color: color('primary');
        filter: brightness(16.0);
      }

      .text-center .mjx-math {
        max-width: calc(100vw - 50px);
        overflow-x: auto;
        overflow-y: hidden;
        padding: 20px 0;
      }

      pre {
        overflow-x: auto;
        overflow-y: hidden;

        & > code {
          max-width: calc(100vw - 50px);
        }
      }
    }
  }
</style>
