import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueResource from 'vue-resource'
import VueCookies from 'vue-cookies'
import MarkdownIt from 'markdown-it'
import hljs from 'highlight.js'
import { v4 as uuid4 } from 'uuid'

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'highlight.js/styles/github-dark.css'
import '@/assets/scss/main.scss'

Vue.use(VueResource)
Vue.use(VueCookies, { expires: -1 })
Vue.use(VueMeta)

import router from './router'
import Api from './api'
import Trans from './trans'
import App from './App.vue'

Vue.config.productionTip = false
Vue.http.options.root = process.env.VUE_APP_API_ROOT

import UpArrowDiv from './components/UpArrowDiv'
import IconSpan from '@/components/IconSpan'
import TitleDiv from '@/components/TitleDiv'
import NavigationDiv from '@/components/NavigationDiv'
import BaseView from '@/views/BaseView'

Vue.component('up-arrow-div', UpArrowDiv)
Vue.component('icon-span', IconSpan)
Vue.component('title-div', TitleDiv)
Vue.component('navigation-div', NavigationDiv)
Vue.component('base-view', BaseView)

new Vue({
  router,
  render: h => h(App),
  beforeCreate() {
    // Set uid cookie
    if (Vue.prototype.$cookies.get('uid') === null) {
      Vue.prototype.$cookies.set('uid', uuid4())
    }

    // Create markdown object
    Vue.prototype.$md = new MarkdownIt(
      {
        html: true,
        highlight: function (str, lang) {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return hljs.highlight(str, {language: lang}).value
            } catch (__) {/**/}
          }
          return ""
        },
      }
    )

    // Define API instance
    Vue.prototype.$api = new Api(this.$resource)

    // Define Trans instance
    Vue.prototype.$trans = new Trans()
  },
}).$mount('#app')
