<template>
  <div class="-self py-3 text-center">
    <span class="button p-2" @click="scrollToTop()">
      <icon-span type="arrow-up" fixed-width />
    </span>
  </div>
</template>

<script>
  export default {
    methods: {
      scrollToTop() {
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        })
      },
    }
  }
</script>
