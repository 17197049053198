<template>
  <div class="menu" @click.self="hide()" 
       :class="{'is-hidden': !isShown, active: isActive}">
    <div class="menu-container" :class="{active: isActive}">
      <div class="p-2">
        <div class="px-3 py-2">
          <span class="title font-title">{{ $trans.translate("Menu") }}</span>
        </div>
        <div>&nbsp;</div>
        <div v-for="item in items" :key="item.title" 
             class="menu-item py-1 px-3 font-menu">
          <router-link :to="item.link" @click.native="hide()">
            {{ $trans.translate(item.title) }}
          </router-link>
        </div>
        <div class="media-block py-2">
          <div class="sep-div"></div>
          <div class="py-2 px-3"><lang-div /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import LangDiv from '@/components/LangDiv'

  export default {
    components: {
      LangDiv,
    },
    data() {
      return {
        isShown: false,
        isActive: false,
        items: [
          {
            title: 'Home',
            link: '/',
          },
          {
            title: 'Blog',
            link: '/blog',
          },
          {
            title: 'Projects',
            link: '/projects',
          },
          {
            title: 'Files',
            link: '/files',
          },
          {
            title: 'Contacts',
            link: '/contacts',
          },
        ],
      }
    },
    methods: {
      show() {
        this.isShown = true
        setTimeout(() => {
          this.isActive = true
        }, 0)
      },

      hide() {
        this.isActive = false
        setTimeout(() => {
          this.isShown = false
        }, 500)
      },

      toggle() {
        if (this.isShown) {
          this.hide()
        }
        else {
          this.show()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vars';

  .menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 0.5s;

    &.active {
      background-color: rgb(0, 0, 0, 0.75);
    }

    .menu-container {
      background-color: color("bg-secondary");
      display: inline-block;
      position: absolute;
      left: -100%;
      height: 100%;
      transition: 0.5s;

      &.active {
        left: 0;
      }

      .menu-item a {
        text-decoration: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        color: color('light');
        font-size: 24px;

        &:after {
          color: color("secondary");
          content: " \2192";
          opacity: 0.0;
          transition: opacity 0.5s linear;
        }

        &:hover:after {
          opacity: 1.0;
        };
      }

      .title {
        font-size: 32px;
        color: color("light");
        padding-bottom: 0.25rem;
        border-bottom: 5px solid color("secondary");
        text-transform: uppercase;
      }
    }
  }
</style>
