<template>
  <stars-div :density="0.0002" 
             :saturation="5" 
             :star-size="2">
    <div class="container px-2">
      <div class="div-flex">
        <div class="py-2">
          <a href="#" @click="$emit('show-menu')">
            <icon-span class="menu-burger" type="bars" size="2x" />
          </a>
        </div>
        <div>
          <img alt="Alex Fomalhaut Logo" src="@/assets/logo.svg" 
               height="60" width="60">
        </div>
        <div class="media-none py-2">
          <lang-div />
        </div>
      </div>
      <div>
        <slot></slot>
      </div>
    </div>
  </stars-div>
</template>

<script>
  import StarsDiv from '@/components/StarsDiv'
  import LangDiv from '@/components/LangDiv'

  export default {
    components: {
      StarsDiv,
      LangDiv,
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vars';

  .menu-burger {
    transition: 0.5s;
    color: color('light');

    &:hover {
      color: color('secondary');
    }
  }
</style>
