<template>
  <div v-if="chain">
    <div class="py-1">
      <span v-for="(item, idx) in chain" :key="idx">
        <span v-if="idx > 0" class="text-muted">&nbsp;&sol;&nbsp;</span>
        <router-link :to="item.href" 
                     :class="{'text-muted': idx < chain.length - 1}">
          {{ $trans.translate(item.name) }}
        </router-link>
      </span>
    </div>
    <div class="sep-div"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        chain: null,
      }
    },
    methods: {
      getNavigationChain(pathname) {
        // If path exists
        if (this.$router.matcher.match(pathname).name != "Error404") {
          // Cumulative URL
          let href = ""

          // Chain to return
          let chain = []

          // Loop for splitted pathname
          for (const item of pathname.split('/')) {
            // Append cumulative URL
            if (href != "/") {
              href += "/"
            }
            href += item

            // Resolve the view name
            const name = this.$router.matcher.match(href).name

            // If the name exists add URL to the chain
            if (name != "Error404") {
              chain.push({name, href})
            }
          }

          // Return the chain
          return chain

        } else {
          // Return navigation for error 404 if the path does not exist
          return [
            {name: 'Home', href: '/'},
            {name: 'Error404', href: '/error404'},
          ]
        }
      },
    },
    mounted() {
      this.chain = this.getNavigationChain(window.location.pathname)
    },
  }
</script>
