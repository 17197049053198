<template>
  <base-view class="-self">
    <template #header>
      <navigation-div />
      <title-div>{{ $trans.translate("Contacts") }}</title-div>
    </template>
    <template #default>
      <div class="media-flex" v-if="items">
        <div>
          <div class="item py-1" v-for="item in itemsFirstHalf()" 
               :key="item.id">
            <a :href="item.url" target="blank">
              <icon-span :type="item.icon" 
                         :pref="resolvePref(item.icon)" 
                         :fixed-width="true"
                         size="1x"
                         class="icon" />
              <span class="px-2">{{ item.name }}</span>
            </a>
          </div>
        </div>
        <div>
          <div class="item py-1" v-for="item in itemsSecondHalf()" 
               :key="item.id">
            <a :href="item.url" target="blank">
              <icon-span :type="item.icon" 
                         :pref="resolvePref(item.icon)" 
                         :fixed-width="true"
                         size="1x"
                         class="icon" />
              <span class="px-2">{{ item.name }}</span>
            </a>
          </div>
        </div>
      </div>
    </template>
  </base-view>
</template>

<script>
  export default {
    data() {
      return {
        items: null,
      }
    },
    methods: {
      itemsFirstHalf() {
        return this.items.slice(0, Math.ceil(this.items.length / 2))
      },

      itemsSecondHalf() {
        return this.items.slice(Math.ceil(this.items.length / 2))
      },

      resolvePref(icon) {
        return (icon == 'envelope') ? 'fa' : 'fab'
      },
    },
    async mounted() {
      const resp = await this.$api.contacts.get()
      this.items = resp.body
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vars';

  .-self {
    .media-flex {
      justify-content: space-around;

      .item {
        a {
          color: color("light");
        }

        .icon {
          width: 18px;
          height: 18px;
          line-height: 18px;
          border: 1px solid color("primary");
          border-radius: 200px;
          padding: 10px;
          box-shadow: 0 0 5px color("primary");
          background-color: color("bg-secondary");
        }

        &:hover {
          a {
            color: color("secondary");
          }

          .icon {
            border: 1px solid color("secondary");
            box-shadow: 0 0 5px color("secondary");
          }
        }
      }
    }
  }
</style>
