<template>
  <div id="app">
    <div v-if="isReady">
      <router-view />
    </div>
    <div class="spinner" v-else>
      <icon-span class="menu-burger" type="spinner" size="5x" spin />
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  const META_DEFAULT = {
    title: "Alexander Khlebushchev",
    description: "Alexander Khlebushchev - Personal Website",
    image: require("@/assets/images/alexander-khlebushchev.png"),
  }

  export default {
    data() {
      return {
        isReady: false,
        meta: { ...META_DEFAULT },
      }
    },
    methods: {
      async ensureCsrfToken() {
        // Get CSRF token from cookie
        let csrftoken = this.$cookies.get('csrftoken')

        // If no token get it from the API and set to cookie
        if (csrftoken === null) {
          const resp = await this.$http.get('csrf')
          csrftoken = resp.body.csrftoken
          this.$cookies.set('csrftoken', csrftoken)
        }

        // Set X-CSRFToken header for each request to API
        Vue.http.headers.common['X-CSRFToken'] = csrftoken
      },

      async loadTranslations() {
        // Collect dictionaries from API
        const dictionaries = {
          en: {},
          ru: {},
          eo: {},
        }
        const resp = await this.$api.trans.get()
        for (const item of resp.body) {
          dictionaries[item.lang][item.key] = item.target
        }

        // Set dictionaries for Trans instance
        this.$trans.addDictionary(dictionaries.en, 'en')
        this.$trans.addDictionary(dictionaries.ru, 'ru')
        this.$trans.addDictionary(dictionaries.eo, 'eo')

        // Set the current language
        this.$trans.lang = this.$cookies.get('lang')
        if (this.$trans.lang === null) {
          this.$trans.lang = 'en'
          this.$cookies.set('lang', this.$trans.lang)
        }

        // Set empty handler
        this.$trans.emptyHandler = this.$api.trans.save
      },
    },
    async mounted() {
      // Ensure X-CSRFToken to request header from cookie
      await this.ensureCsrfToken()

      // Load translations
      await this.loadTranslations()

      // Listen to events
      this.$root.$on('set-meta', meta => {
        this.meta.title = meta.title ? meta.title : META_DEFAULT.title
        this.meta.description = (
          meta.description ? meta.description : META_DEFAULT.description
        )
        this.meta.image = meta.image ? meta.image : META_DEFAULT.image
      })

      // Set isReady to true
      this.isReady = true
    },
    metaInfo() {
      return {
        title: this.meta.title,
        meta: [
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:title',
            content: this.meta.title,
          },
          {
            property: 'og:description',
            content: this.meta.description,
          },
          {
            property: 'og:image',
            content: this.meta.image,
          },
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>
  .spinner {
    text-align: center;
    line-height: 100vh;
  }
</style>
