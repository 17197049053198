<template>
  <base-view>
    <template #header>
      <navigation-div />
      <title-div>{{ $trans.translate("Projects") }}</title-div>
    </template>
    <template #default>
      <table class="table" v-if="items">
        <tr v-for="(item, idx) in items" :key="item.id">
          <td class="text-large py-2 color-primary">{{ zfill(idx + 1, 2) }}</td>
          <td class="px-3 py-2">
            <div class="text-large pb-1">{{ item.name }}</div>
            <div class="pb-1" v-if="item.source_url">
              <a :href="item.source_url" class="text-muted" target="blank">
                {{ $trans.translate("Source code") }}
              </a>
            </div>
            <div class="pb-1 text-text">
              <span v-html="$md.render(item.text)"></span>
              <router-link :to="`/blog/article/${item.article_short_url}`" 
                           class="is-secondary" v-if="item.article_short_url">
                {{ $trans.translate("Read more") }}...
              </router-link>
            </div>
            <div class="media-block">
              <a :href="item.download_file.url" download 
                 class="button is-primary" v-if="item.download_file">
                <span>{{ $trans.translate("Download") }}&nbsp;</span>
                <icon-span type="download" />
              </a>
              <a :href="item.web_url" target="blank" class="button is-primary" 
                 v-if="item.web_url">
                <span>{{ $trans.translate("Website") }}&nbsp;</span>
                <icon-span type="link" />
              </a>
            </div>
          </td>
          <td class="py-2 media-none">
            <a :href="item.download_file.url" download 
               class="button is-primary" v-if="item.download_file">
              <span>{{ $trans.translate("Download") }}&nbsp;</span>
              <icon-span type="download" />
            </a>
            <a :href="item.web_url" target="blank" class="button is-primary" 
               v-if="item.web_url">
              <span>{{ $trans.translate("Website") }}&nbsp;</span>
              <icon-span type="link" />
            </a>
          </td>
        </tr>
      </table>

      <up-arrow-div />
    </template>
  </base-view>
</template>

<script>
  import { zfill } from '@/utils'

  export default {
    data() {
      return {
        items: null,
      }
    },
    methods: {
      zfill,
    },
    async mounted() {
      const resp = await this.$api.projects.get({lang: this.$trans.lang})
      this.items = resp.body
    },
  }
</script>

<style lang="scss" scoped>
  .table {
    td {
      text-align: left;

      &:first-child {
        vertical-align: top;
      }

      &:last-child {
        vertical-align: bottom;
      }
    }
  }
</style>
