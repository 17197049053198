<template>
  <div class="-self">
    <title-div>{{ $trans.translate("Contacts") }}</title-div>

    <div class="snake py-2 px-3" v-if="items">
      <div class="row" v-if="!isVertical">
        <div v-for="idx in getHalf()" 
             :key="idx"
             ref="itemCol">
          <div class="item">
            <a :href="items[2 * idx - 2].url" target="blank">
              <icon-span :type="items[2 * idx - 2].icon" 
                         :pref="resolvePref(items[2 * idx - 2].icon)" 
                         :fixed-width="true"
                         size="2x"
                         class="icon" />
            </a>
          </div>

          <div class="py-4"></div>

          <div class="item">
            <a :href="items[2 * idx - 1].url" target="blank" 
               v-if="2 * idx <= items.length">
              <icon-span :type="items[2 * idx - 1].icon" 
                         :pref="resolvePref(items[2 * idx - 1].icon)" 
                         :fixed-width="true"
                         size="2x"
                         class="icon" />
            </a>
          </div>
        </div>
      </div>

      <div v-if="isVertical">
        <div v-for="idx in getHalf()" 
             :key="idx"
             ref="itemCol"
             class="row mb-5">
          <div class="item">
            <a :href="items[2 * idx - 2].url" target="blank">
              <icon-span :type="items[2 * idx - 2].icon" 
                         :pref="resolvePref(items[2 * idx - 2].icon)" 
                         :fixed-width="true"
                         size="2x"
                         class="icon" />
            </a>
          </div>

          <div></div>

          <div class="item">
            <a :href="items[2 * idx - 1].url" target="blank" 
               v-if="2 * idx <= items.length">
              <icon-span :type="items[2 * idx - 1].icon" 
                         :pref="resolvePref(items[2 * idx - 1].icon)" 
                         :fixed-width="true"
                         size="2x"
                         class="icon" />
            </a>
          </div>
        </div>
      </div>

      <div class="line" 
           v-for="(line, idx) in lines" 
           :key="idx"
           :style="{
             left: line.left + 'px', 
             top: line.top + 'px', 
             width: line.width + 'px',
             height: line.height + 'px',
           }"
      ></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isVertical: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        items: null,
        lines: [],
      }
    },
    methods: {
      getHalf() {
        return Math.ceil(this.items.length / 2)
      },

      itemsFirstHalf() {
        return this.items.slice(0, Math.ceil(this.items.length / 2))
      },

      itemsSecondHalf() {
        return this.items.slice(Math.ceil(this.items.length / 2))
      },

      resolvePref(icon) {
        return (icon == 'envelope') ? 'fa' : 'fab'
      },

      drawLines() {
        if (this.items && (this.items.length >= 2)) {
          if (this.isVertical) {
            this.drawLinesV()
          } else {
            this.drawLinesH()
          }
        }
      },

      drawLinesH() {
        // Clear lines
        this.lines.length = 0

        // Accumulate centers of contact divs
        let coords1 = []
        let coords2 = []

        for (const col of this.$refs['itemCol']) {
          coords1.push(this.getDivCenter(col.children[0]))
          coords2.push(this.getDivCenter(col.children[2]))
        }

        // Remove extra (empty) div from coords2
        if (this.items.length % 2 == 1) {
          coords2.pop()
        }

        if (coords1.length && coords2.length) {
          // Build vertical lines
          for (let i = 0; i < Math.min(coords1.length, coords2.length); i++) {
            const line = {
              left: coords1[i][0],
              top: coords1[i][1],
              width: 2,
              height: coords2[i][1] - coords1[i][1],
            }
            this.lines.push(line)
          }

          // Build horizontal lines
          const level1 = coords1[0][1]
          const level2 = coords2[0][1]
          let level = level1

          for (let i = Math.max(coords1.length, coords2.length) - 2; 
                   i >= 0; i--) {
            const line = {
              left: coords1[i][0],
              top: level,
              width: coords1[i + 1][0] - coords1[i][0],
              height: 2,
            }
            this.lines.push(line)
            level = level1 + level2 - level
          }
        }
      },

      drawLinesV() {
        // Clear lines
        this.lines.length = 0

        // Accumulate centers of contact divs
        let coords1 = []
        let coords2 = []

        for (const col of this.$refs['itemCol']) {
          coords1.push(this.getDivCenter(col.children[0]))
          coords2.push(this.getDivCenter(col.children[2]))
        }

        // Remove extra (empty) div from coords2
        if (this.items.length % 2 == 1) {
          coords2.pop()
        }

        if (coords1.length && coords2.length) {
          // Build horizontal lines
          for (let i = 0; i < Math.min(coords1.length, coords2.length); i++) {
            const line = {
              left: coords1[i][0],
              top: coords1[i][1],
              width: coords2[i][0] - coords1[i][0],
              height: 2,
            }
            this.lines.push(line)
          }

          // Build vertical lines
          const level1 = coords1[0][0]
          const level2 = coords2[0][0]
          let level = level1

          for (let i = Math.max(coords1.length, coords2.length) - 2; 
                   i >= 0; i--) {
            const line = {
              left: level,
              top: coords1[i][1],
              width: 2,
              height: coords1[i + 1][1] - coords1[i][1],
            }
            this.lines.push(line)
            level = level1 + level2 - level
          }
        }
      },

      getDivCenter(div) {
        return [
          div.offsetLeft + div.offsetWidth / 2,
          div.offsetTop + div.offsetHeight / 2,
        ]
      },
    },
    async mounted() {
      // Get contacts
      const resp = await this.$api.contacts.get()
      this.items = resp.body

      // Draw
      this.$nextTick(() => {
        this.drawLines()
      })

      // Redraw on load
      window.addEventListener("load", () => {
        this.drawLines()
      })

      // Redraw on resize
      window.addEventListener("resize", () => {
        this.drawLines()
      })
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vars';

  .-self {
    .snake {
      .row {
        display: flex;
        justify-content: space-between;

        .item {
          a {
            color: color("light");
          }

          .icon {
            width: 42px;
            height: 42px;
            line-height: 42px;
            border: 2px solid color("primary");
            border-radius: 200px;
            padding: 10px;
            box-shadow: 0 0 5px color("primary");
            background-color: color("bg-secondary");
            outline: 1px solid rgba(color("primary"), 0.25);
            outline-offset: 24px;
          }

          &:hover {
            a {
              color: color("secondary");
            }

            .icon {
              outline: 1px solid rgba(color("primary"), 0.75);
            }
          }
        }
      }

      .line {
        position: absolute;
        background-color: color("primary");
        box-shadow: 0 0 10px color("primary");
        z-index: -1;
      }
    }
  }
</style>
