import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from '@/views/HomeView'
import BlogView from '@/views/BlogView'
import ArticleView from '@/views/ArticleView'
import ContactsView from '@/views/ContactsView'
import FilesView from '@/views/FilesView'
import ProjectsView from '@/views/ProjectsView'
import CvView from '@/views/CvView'
import Error404View from '@/views/Error404View'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogView,
  },
  {
    path: '/blog/article/:shorturl',
    name: 'Article',
    component: ArticleView,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: ContactsView,
  },
  {
    path: '/files',
    name: 'Files',
    component: FilesView,
  },
  {
    path: '/projects',
    name: 'Projects',
    component: ProjectsView,
  },
  {
    path: '/cv',
    name: 'CV',
    component: CvView,
  },
  {
    path: '*',
    name: 'Error404',
    component: Error404View,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
