<template>
  <div class="pt-2 pb-3">
    <div class="title-div" 
         :class="borderLeft ? 'border-left' : 'border-bottom'">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      borderLeft: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vars';
  
  .title-div {
    display: inline-block;
    text-transform: uppercase;

    font-family: $font-default;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;

    &.border-left {
      border-left: 5px solid color('secondary');
      padding-left: 15px;
    }

    &.border-bottom {
      border-bottom: 5px solid color('secondary');
    }
  }
</style>
